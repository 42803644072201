body, html {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background: linear-gradient(135deg, #ffffff, #99edf8);
  background-attachment: fixed; /* Ensure the background gradient is fixed */
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

h1 {
  display: flex;
  align-items: center;
  color: #007bff;
  font-size: 2.5rem; /* Add this line to increase the font size */
  margin: 20px 0; /* Add this line to add some margin */
}

.sub-header {
  color: #0056b3;
  margin-bottom: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.papers {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.paper {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 0;
  padding: 16px;
  margin: 16px;
  width: 80%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.paper:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.paper-info {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.paper-chart {
  margin-left: 20px;
}

.paper h2 {
  margin-top: 0;
  color: #007bff;
  text-align: left;
}

.paper p {
  text-align: left;
}

.paper h2 a {
  color: black; /* Keep the link color black */
  text-decoration: none; /* Remove underline */
  transition: color 0.2s; /* Add a transition effect */
  background-color: transparent; /* Ensure no background color */
}

.paper h2 a:hover {
  color: #007bff; /* Change color on hover to indicate it's a link */
  background-color: transparent; /* Ensure no background color on hover */
}

.paper a {
  color: #007bff;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #007bff; /* Revert to blue background */
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.2s;
  margin-top: auto; /* Ensure it stays at the bottom of the flex container */
}

.paper a:hover {
  background-color: #0056b3;
  text-decoration: none;
}

.paper a svg {
  margin-right: 8px;
}

.paper img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.blur {
  filter: blur(5px);
  transition: filter 0.3s;
}

/* Add these styles to your existing CSS file */
.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.75) !important;
    z-index: 1000; /* Ensure the overlay is on top */
}

.ReactModal__Content {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 10px;
    outline: none;
    max-width: 500px;
    width: 90%;
}

.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 10px;
    outline: none;
    max-width: 500px;
    width: 90%;
}

.Overlay {
    background-color: rgba(0, 0, 0, 0.75) !important;
}

.chart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
