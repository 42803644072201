.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    /* background-color: #f0f8ff; */
    /* background: linear-gradient(135deg, #f0f8ff, #e0f7fa); */
    background: transparent;
}

h1 {
    color: #007bff;
    font-size: 2.5rem; /* Match the font size */
    margin: 20px 0; /* Add some margin */
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
}

input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #007bff;
    border-radius: 5px;
}

button {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}

.loading-text {
    color: #0056b3; /* Dark blue color */
}