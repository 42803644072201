/* src/components/LandingPage.css */

body, html {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background: linear-gradient(135deg, #ffffff, #99edf8);
  background-attachment: fixed;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.header {
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.logo {
  font-size: 28px;
  font-weight: bold;
  color: #007bff;
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  justify-content: center; /* Center content vertically */
  text-align: center;
  padding: 50px 20px;
  max-width: 800px;
  margin: 0 auto; /* Center the main content container */
  color: #333;
}

.main-content h1 {
  font-size: 48px;
  margin-bottom: 20px;
}

.main-content p {
  font-size: 18px;
  margin-bottom: 40px;
}

.get-started-button {
  padding: 15px 30px;
  font-size: 18px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.get-started-button:hover {
  background-color: #0056b3;
}

.steps-section {
  width: 100%;
  background: white;
  padding: 50px 0;
}

.step-block {
  display: flex;
  width: 100%;
  padding: 50px 20px;
  box-sizing: border-box;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow to the step blocks */
  margin-bottom: 20px; /* Add spacing between step blocks */
  background: #f9f9f9; /* Add a light background color */
  border-radius: 15px; /* Round corners of step blocks */
}

.step-block.image-left .step-content {
  order: 1;
}

.step-block.image-left .step-image {
  order: 2;
}

.step-block.image-right .step-content {
  order: 2;
}

.step-block.image-right .step-image {
  order: 1;
}

.step-content {
  width: 50%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  justify-content: center; /* Center content vertically */
  text-align: center; /* Center text */
}

.step-content h2 {
  font-size: 36px;
  color: #007bff;
  margin-bottom: 20px;
}

.step-content p {
  font-size: 20px;
  color: #555;
}

.step-image {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step-image img {
  max-width: 100%; /* Increase the size of the images */
  height: auto;
  object-fit: contain;
  border-radius: 15px; /* Apply rounded corners */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3); /* Add more shadow to images */
}

.icon {
  margin-bottom: 20px; /* Add spacing below the icon */
}
